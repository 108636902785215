var Perseides = Perseides || {};
(function ($, window, document, Perseides, Analyzr, undefined) {
    "use strict";

    window.PerseidesJquery = function () {
        var app = this;

        /**
         * Extension jQuery
         */
        $.fn.extend({

            outerHTML: function(s) {
                return s
                    ? this.before(s).remove()
                    : jQuery("<p>").append(this.eq(0).clone()).html();
            }
        });

    };

    Perseides.Jquery = new PerseidesJquery();
}(jQuery, window, document, Perseides, Analyzr));