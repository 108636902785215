var Perseides = Perseides || {};
(function ($, window, document, Perseides, Analyzr, undefined) {
    "use strict";

    window.PerseidesFunctions = function () {
        var app = this;


        /**
         * Inject SVG into BODY
         */
        app.injectSvg = function (file, path) {
            "use strict";

            if (path === undefined) {
                path = '';
            }

            if (Analyzr.isFolderStyleguide === true) {
                path = Perseides.Base.svgPath;
            }

            var request = new XMLHttpRequest();
            request.open('GET', path + file, true);

            request.onload = function () {
                if (request.status >= 200 && request.status < 400) {
                    // Success!
                    var resp = request.responseText,
                        $body = $('body'),
                        htmlToPrepend = '<div style="height: 0; width: 0; position: absolute; visibility: hidden">' + resp + '</div>';

                    $body.prepend(htmlToPrepend);
                } else {
                    // We reached our target server, but it returned an error
                    console.log('We reached our target server, but it returned an error');
                }
            };

            request.onerror = function () {
                // There was a connection error of some sort
                console.log('There was a connection error of some sort');
            };

            request.send();
        };




        /**
         * outputSVGIcons
         *
         * Loops through all elements on the page that need icons added to them.
         *
         * @param rootLayer (jQuery object): the root layer to search inside to replace icons. (default $('body'))
         *
         */
        app.outputSVGIcons = function () {
            "use strict";

            var uiIcons,
                brandIcons,
                rootLayer = $(Perseides.Base.rootLayer);

            var outputIcons = function (iconElements, brand) {

                iconElements.each(function () {

                    var thisItem = $(this);
                    var iconID = brand ? thisItem.attr('data-brandicon') : thisItem.attr('data-icon');
                    var iconClass = thisItem.attr('data-icon-element-class');

                    var icon;

                    if (brand) {
                        icon = $('<svg class="brandicon ' + iconClass + '"><use xlink:href="#brandicon-' + iconID + '"/></svg>');
                    }
                    else {
                        icon = $('<svg class="icon-' + iconID + ' ' + iconClass + '"><use xlink:href="#icon-' + iconID + '"/></svg>');
                    }

                    // If there's a class name to append to the SVG element, add that class
                    if (iconClass) {
                        icon.addClass(iconClass);
                    }

                    // If the data-icon-position value is set to "before" prepend the icon to the containing element. If not, append by default.
                    if (thisItem.attr('data-icon-position') === 'before') {
                        icon.prependTo(thisItem);
                    }
                    else {
                        icon.appendTo(thisItem);
                    }
                });

            };

            // Function that loads the brand icons icon-set and outputs it to the dom
            var loadBrandIcons = function (callback) {
                $.ajax({
                    url: config.brandIconsURL,
                    dataType: 'html',
                    success: function (data) {
                        $(data).prependTo($('body'));
                        config.brandIconsLoaded = true;

                        if (callback) {
                            callback();
                        }
                    }
                });
            };

            uiIcons = rootLayer.find('[data-icon]');

            outputIcons(uiIcons);

            // Look for Brand Icons
            brandIcons = rootLayer.find('[data-brandicon]');

            // If there are brand icons, output them
            if (brandIcons.length) {
                if (config.brandIconsLoaded) {
                    outputIcons(brandIcons, true);
                }
                else {
                    loadBrandIcons(function () {
                        outputIcons(brandIcons, true);
                    });
                }
            }

        };




        /**
         * initializeSlickNav
         *
         * Fonction de base d'initialisation de slickNav
         *
         * @param menuId (jQuery object) : Sélecteur du menu à utiliser pour slickNav
         * @param customParams (object) : Objet de paramètres slickNav personnalisés ajouté aux paramètres de base
         * @param customCallbacks (object) : Objet de callbacks slickNav personnalisés ajouté aux paramètres de base
         */
        app.initializeSlickNav = function (menuId, customParams, customCallbacks) {
            "use strict";

            var $menu = $(menuId);

            if(customParams === null) {
                customParams = {};
            }

            if(customCallbacks === null) {
                customCallbacks = {};
            }

            if($menu.length !== 0) {

                var params = {
                    allowParentLinks: true,
                    nestedParentLinks: false,
                    prependTo: "#menuMobile",
                    removeClasses: false//,
                    //beforeOpen: function (trigger) {
                    //    app.slicknavOpened(trigger);
                    //},
                    //init: function () {
                    //    //setTimeout(Perseides.Functions.slickNavPostInit_CopyUtilNav(), 10);
                    //    //setTimeout(Perseides.Functions.slickNavPostInit_mainMenuCopyLogo(), 20);
                    //    setTimeout(app.slickNavTransformFirstLevelLinksIntoSpan(), 30);
                    //}
                };

                $.extend(params, customParams, customCallbacks);
                $menu.slicknav(params);
            }
        };




        /**
         * slickNavPostInit_AddNav
         *
         * Après l'initialisation de slickNav, on ajoute des éléments précis dans l'arborescence du menu slickNav
         *
         * @param slickNavTarget (jQuery object) : Menu SlickNav que l'on veut ajouter ces éléments
         * @param elements (array) : Tableau de sélecteurs à ajouter au menu slickNav
         * @param classes (array) : Tableau de classes à ajouter au <li> si on a besoin d'identifier les éléments ajoutés
         * @param params (array) : Tableau de paramètres pour différents cas
         */
        app.slickNavPostInit_AddNav = function (slickNavTarget, elements, classes, params) {

            var $slickNavTarget = $(slickNavTarget);

            if($slickNavTarget.length !== 0) {

                Array.prototype.forEach.call(elements, function(el, i){

                    var $elementToAppend = $(el),
                        classToAppend = classes[i],
                        param = '';

                    if($elementToAppend.length > 0) {
	                    if(params !== undefined) {
		                    param = params[i];
	                    }

	                    if(param == 'isMenuPrinc') {
		                    // Non fonctionnel pour le moment, ça ajoute les <li>, mais les sous-menus ne sont pas traité par SlickNav
		                    $elementToAppend.find('*').removeAttr('class');
		                    $slickNavTarget.find('.slicknav_menu .slicknav_nav').append($elementToAppend.html());
	                    }
	                    else {
		                    $slickNavTarget.find('.slicknav_menu .slicknav_nav').append('<li class="' + classToAppend + '">' + $elementToAppend.outerHTML() + '</li>');
	                    }
                    }

                });

            }
        };




        /**
         * slicknavOpened
         *
         * This function makes one parent menu close before another open
         *
         * Function created by cwellsx on slickNav gitHub issues forum
         * https://github.com/ComputerWolf/SlickNav/issues/16#issuecomment-46667718
         */
        app.slicknavOpened = function (trigger) {
            var $trigger = $(trigger[0]);
            if ($trigger.hasClass('slicknav_btn')) {
                // this is the top-level menu/list opening.
                // we only want to trap lower-level/sublists.
                return;
            }
            // trigger is an <a> anchor contained in a <li>
            var $liParent = $trigger.parent();
            // parent <li> is contained inside a <ul>
            var $ulParent = $liParent.parent();
            // loop through all children of parent <ul>
            // i.e. all siblings of this <li>
            $ulParent.children().each(function () {
                var $child = $(this);
                if ($child.is($liParent)) {
                    // this child is self, not is sibling
                    return;
                }
                if ($child.hasClass('slicknav_collapsed')) {
                    // this child is already collapsed
                    return;
                }
                if (!$child.hasClass('slicknav_open')) {
                    // could throw an exception here: this shouldn't happen
                    // because I expect li to have class either slicknav_collapsed or slicknav_open
                    return;
                }
                // found a sibling <li> which is already open.
                // expect that its first child is an anchor item.
                var $anchor = $child.children().first();
                if (!$anchor.hasClass('slicknav_item')) {
                    return;
                }
                // close the sibling by emulating a click on its anchor.
                $anchor.click();
            });
        };




        /**
         * slickNavPostInit_mainMenuCopyLogo
         *
         * Après l'initialisation de SlickNav, on prend le logo du site et on le met dans le menu principal,
         * au milieu, entre les menus. On fait ce traitement après que SlickNav ait été initialisé, car on ne
         * veut pas que le logo soit dans le menu mobile
         *
         */
        app.slickNavPostInit_mainMenuCopyLogo = function (targetLogo, targetMenu) {

            var logo = document.getElementById(targetLogo).innerHTML,
                $mainMenu = document.getElementById(targetMenu),
                posInsert = $mainMenu.children.length / 2 - 1;

            // Ajout du logo au milieu du menu
            $mainMenu.children[posInsert].classList.add('menu__container__item--logo--before');
            $mainMenu.children[posInsert+1].classList.add('menu__container__item--logo--after');
            $mainMenu.children[posInsert].insertAdjacentHTML('afterEnd', '<li class="logo menu__container__item menu__container__item--logo">' + logo + '</li>');
        };




        /**
         * stickyHeaderManagement
         *
         * http://jsfiddle.net/livibetter/HV9HM/
         */
        app.stickyHeaderManagement = function (firstLoad) {

            var $html = $('html');
            var window_top = $(window).scrollTop();

            if (!firstLoad) {
                firstLoad = false;
            }

            // Sticky  Main Menu
            if (window_top > 200 && (
                    Analyzr.isTabletMediumSize === true ||
                    Analyzr.isTabletLargeSize === true ||
                    Analyzr.isDesktopSize === true
                )
            ) {
                $($html).addClass('sticky-header');
            } else {
                $($html).removeClass('sticky-header');
            }

            if (firstLoad === true) {
                $($html).addClass('first-load');
            }
            else {
                $($html).removeClass('first-load');
            }
        };




        /**
         * linkDeactivateFirstClickForSubNav
         *
         * Dans le cas où un menu ou un lien a un sous-menu et que l'on doit afficher ce sous-menu sur un
         * appareil "touch", il faut désactiver le lien principal une première fois pour afficher le
         * sous-menu. Ensuite, on réactive le clic sur le lien pour accéder à la page, le cas échéant.
         */
        app.linkDeactivateFirstClickForSubNav = function (selecteurID, selecteurClass) {
            "use strict";

            var $selecteurID = document.getElementById(selecteurID);

            if(Analyzr.isTouchEventsSupported === true && $selecteurID !== null) {
                var $selecteurClass = $selecteurID.querySelectorAll(selecteurClass);

                if($selecteurClass.length !== 0) {

                    Array.prototype.forEach.call($selecteurClass, function(el, i){

                        el.addEventListener(Analyzr.clickORtouchend, function (event) {

                            if(el.classList.contains('firstClickDone') !== true) {

                                event.preventDefault();
                                el.classList.add('firstClickDone');
                            }
                        });
                    });
                }
            }
        };




        /**
         * validFormContact
         *
         * Validation du formulaire de contact
         */
        app.validFormContact = function (cibles, valeurs, erreurMail, erreurCapcha, erreursMessagesArray) {
            "use strict";

            var required = false,
                validEmail = false,
                validCapcha = false,
                cible = '',
                i = 0,
                valeur1 = '',
                valeur2 = '';

            /* Mettre une classe d'erreur sur les champs vide */
            if(cibles != "") {
                cible = cibles.split(",");
                for(i=0; i < cible.length; i++) {
                    if(cible[i] != "") {
                        $('#' + cible[i]).parent('.formfield').addClass('formfield--invalid');
                        required = true;
                    }
                }
            }

            /* Remettre les valeurs dans les champs s'il y a une erreur */
            valeur1 = valeurs.split(";;");
            for(i=0; i < valeur1.length; i++) {
                valeur2 = valeur1[i].split("!=!");
                if(valeur2[1] != "") {
                    if (document.getElementById(valeur2[0]).tagName == "TEXTAREA") {
                        $('#' + valeur2[0]).html(valeur2[1]);
                    }else{
                        $('#' + valeur2[0]).attr('value', valeur2[1]);
                    }
                }
            }

            /* Si le courriel est en erreur, mettre son champ en rouge */
            if(erreurMail) {
                $('#contactEmail').parent('.formfield').addClass('formfield--invalid');
                validEmail = true;
            }

            /* Si le capcha est en erreur, mettre son champ en rouge */
            if(erreurCapcha) {
                $('#contactCapcha').parent('.formfield').addClass('formfield--invalid');
                validCapcha = true;
            }

            /* Activer le block d'erreur */
            var texteErreur = "",
                $formErreur = $('#formErreur');

            $formErreur.css('display', 'flex');
            if(required) { texteErreur += "<li>" + erreursMessagesArray[0] + "</li>"; }
            if(validEmail) { texteErreur += "<li>" + erreursMessagesArray[1] + "</li>"; }
            if(validCapcha) { texteErreur += "<li>" + erreursMessagesArray[2] + "</li>"; }

            $formErreur.find('.message--erreur__list').html(texteErreur);
        };




        /**
         * initLightbox
         *
         * Initialisation du Lightbox
         */
        app.initLightbox = function (selecteur) {
            "use strict";

            var $selecteur = $(selecteur);

            if($selecteur.length !== 0) {

                $selecteur.littleLightBox({
                    'padding': 10,
                    'minHeight': 100,
                    'openSpeed': 200,
                    'closeSpeed': 200,
                    'changeSpeed': 200
                });

            }
        };




        /**
         * addTableContainer
         *
         * Ajout d'un container pour les <table> dans une page pour que le tableau soit "responsive"
         */
        app.addTableContainer = function () {
            "use strict";

            var rootLayer = document.getElementById(Perseides.Base.rootLayerNatif),
                $tables = rootLayer.querySelectorAll('table');

            if($tables.length !== 0) {

                // Création des containers et insertion du tableau dans le container
                Array.prototype.forEach.call($tables, function(e, i){
                    e.insertAdjacentHTML('afterend', '<div id="table' + i + '" class="table-container">' + e.outerHTML + '</div>');
                    e.parentNode.removeChild(e);
                    document.getElementById('table' + i).removeAttribute('id');
                });
            }
        };




        /**
         * googleMap
         *
         * Initialisation d'une carte Google
         */
        app.googleMap = function(selecteurID, position, couleurMap, mapOptions, markerOptions) {
            "use strict";

            var gmap = this;

            gmap.$selecteurID = document.getElementById(selecteurID);
            gmap.latitude = position[0];
            gmap.longitude = position[1];
            gmap.mapOptions = mapOptions;
            gmap.markerOptions = markerOptions;

            gmap.initMap = function (cible, latitude, longitude, mapOpts, markerOpts) {

                var directionsService = new google.maps.DirectionsService(),
                    directionsDisplay = new google.maps.DirectionsRenderer(),
                    latLng = new google.maps.LatLng(latitude, longitude);

                var mapOptions = {
                    zoom: mapOpts.zoom,
                    scrollwheel: false,
                    navigationControl: false,
                    mapTypeControl: false,
                    scaleControl: true,
                    draggable: true,
                    center: latLng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };

                var roadAtlasStyles = couleurMap;

                var map = new google.maps.Map(cible, mapOptions);

                var marker = new google.maps.Marker({
                    position: latLng,
                    title: markerOpts.title,
                    icon: markerOpts.icon,
                    animation: google.maps.Animation.DROP,
                    visible: true
                });
                marker.setMap(map);

                var styledMapOptions = {};

                var usRoadMapType = new google.maps.StyledMapType(
                    roadAtlasStyles, styledMapOptions);

                map.mapTypes.set('usroadatlas', usRoadMapType);
                map.setMapTypeId('usroadatlas');

                directionsDisplay.setMap(map);
            }

            // Si le sélecteur de la carte existe sur la page, on initialise la carte Google
            if(gmap.$selecteurID !== null) {

                gmap.initMap(gmap.$selecteurID, gmap.latitude, gmap.longitude, gmap.mapOptions, gmap.markerOptions);

            }
        };




	    /**
	     * initSlickSlider
	     *
	     * Initialisation d'un slider Slick
	     */
	    app.initSlickSlider = function(slider, customParams) {

		    var $slider = $(slider);

		    if(customParams === null) {
			    customParams = {};
		    }

		    if($slider.length !== 0) {

			    var params = {
				    autoplay: true,
				    mobileFirst: true
			    };

			    $.extend(params, customParams);
			    $slider.slick(params);
		    }

        };

    };

    Perseides.Functions = new PerseidesFunctions();
}(jQuery, window, document, Perseides, Analyzr));