var Perseides = Perseides || {};
(function ($, window, document, Perseides, Analyzr, undefined) {
    "use strict";

    window.PerseidesBase = function () {
        var app = this;

        // Paths
        app.basePath = '';
        if(Analyzr.isFolderStyleguide === true) {
            app.basePath = '..';
        }
        else if(Analyzr.server === 'dev') {
            app.basePath = '/origine/campingbelley';
        }

        app.distPath = app.basePath + '/templates/';
        app.svgPath = app.basePath + '/templates/svg/';
        app.imgPath = app.basePath + '/templates/img/';
        app.jsPath = app.basePath + '/templates/js/';


        // Premier niveau de sélecteur
        app.rootLayer = '#ptsite';
        app.rootLayerNatif = 'ptsite';

    };

    Perseides.Base = new PerseidesBase();
}(jQuery, window, document, Perseides, Analyzr));