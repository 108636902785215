var Perseides = Perseides || {};
(function ($, window, document, Perseides, Analyzr, undefined) {
	"use strict";

	window.PerseidesInit = function () {
		var app = this;


		app.initWebsite = function () {
			var rootLayer = $(Perseides.Base.rootLayer);



			// ----------------------------------------------
			// Resize - Throttle
			// ----------------------------------------------
			$(window).on('resize', _.throttle(function (event) {
				"use strict";


				/**
				 * Appel de la fonction analyzeFeatures() de Analyzr
				 */
				Analyzr.analyzeFeatures();

				if (Analyzr.$html.classList.contains('resize-in-progress') === false) {
					Analyzr.$html.classList.add('resize-in-progress');
				}



				/**
				 * Sticky Header
				 */
				Perseides.Functions.stickyHeaderManagement();



				/**
				 * Initialisation du slider de la page d'accueil
				 */
				Perseides.Theme.getHomeSliderImageFromResolution(event);


			}, 200));





			// ----------------------------------------------
			// Resize - Debounce
			// ----------------------------------------------
			$(window).on('resize', _.debounce(function () {
				"use strict";


				Analyzr.$html.classList.remove('resize-in-progress');


			}, 200));




			// ----------------------------------------------
			// Scroll - Throttle
			// ----------------------------------------------
			$(window).on('scroll', _.throttle(function () {
				"use strict";

				/**
				 * Sticky Header
				 */
				Perseides.Functions.stickyHeaderManagement();


			}, 100, {leading: false, trailing: true}));




			// ----------------------------------------------
			// Document READY
			// ----------------------------------------------
			$(document).ready(function () {
				"use strict";

				/**
				 * Injection of SVG icons
				 */
				Perseides.Functions.injectSvg('monochrome.svg', Perseides.Base.svgPath);
				Perseides.Functions.injectSvg('multicolor.svg', Perseides.Base.svgPath);


				/**
				 * Output SVG icons
				 */
				Perseides.Functions.outputSVGIcons();


				/**
				 * Sticky Header
				 */
				Perseides.Functions.stickyHeaderManagement(true);


				/**
				 * Initialisation du lightbox
				 */
				Perseides.Functions.initLightbox($('[rel="lightbox"]'));


				/**
				 * Ajout d'un container pour les <table> dans une page pour que le tableau soit "responsive"
				 */
				Perseides.Functions.addTableContainer();


				/**
				 * Initialisation du menu mobile principal
				 */
				Perseides.Theme.initMainMenuMobile();


				/**
				 * Désactivation du premier clic sur un menu principal contenant un sous-menu
				 */
				Perseides.Theme.menuPrincShowSubMenuOnTouchDevice();


				/**
				 * Initialisation du widget de météo
				 */
				Perseides.Theme.initMeteo('meteo');

				/**
				 * Initialisation du slider de la page d'accueil
				 */
				Perseides.Theme.initHomeSlider();
			});
		};
	};

	Perseides.Init = new PerseidesInit();
}(jQuery, window, document, Perseides, Analyzr));


/**
 * Init!
 */
Perseides.Init.initWebsite();
