var Perseides = Perseides || {};
(function ($, window, document, Perseides, Analyzr, undefined) {
    "use strict";

    window.PerseidesTheme = function () {
        var app = this;

        /**
         * Initialisation du menu mobile principal
         */
        app.initMainMenuMobile = function () {
            "use strict";

            // Variables
            var addToNav = ['#menuLang .menu__container__item__link'],
                addToNavClasses = ['lang'],
                params = [];

            // Paramètres et callbacks
            var customParams = {
                    closedSymbol: '<svg class="icon icon-fleche-droite-arrondie"><use xlink:href="#icon-fleche-droite-arrondie"/></svg>',
                    openedSymbol: '<svg class="icon icon-fleche-bas-arrondie"><use xlink:href="#icon-fleche-bas-arrondie"/></svg>'
                },
                customCallbacks = {
                    init: function () {
                        setTimeout(Perseides.Functions.slickNavPostInit_AddNav($('#menuMobile'), addToNav, addToNavClasses, params), 10);
                        setTimeout(Perseides.Functions.slickNavPostInit_mainMenuCopyLogo('siteLogo', 'menuPrinc'), 20);
                    }
                };

            return Perseides.Functions.initializeSlickNav($('#menuPrinc'), customParams, customCallbacks);
        };


        /**
         * Désactivation du premier clic sur un menu principal contenant un sous-menu
         */
        app.menuPrincShowSubMenuOnTouchDevice = function () {
            "use strict";

            return Perseides.Functions.linkDeactivateFirstClickForSubNav('menuPrinc', '.menu__container__item--parent .menu__container__item__link--lvl1');
        };


        /**
         * Initialisation d'une carte Google
         */
        app.initGoogleMap = function () {
            "use strict";

            // Variables
            var markerSize = [302,22];
            if (Analyzr.isTabletLargeSize === true || Analyzr.isDesktopMediumSize === true || Analyzr.isDesktopLargeSize === true) {
                markerSize = [605,44];
            }

            // Paramètres
            var position = [48.645216, -71.807542];
            var couleurMap = [
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#1c99ed"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#1f79b5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#6d6d6d"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#555555"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#999999"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.landcover",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.government",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.medical",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#e1eddd"
                        }
                    ]
                },
                {
                    "featureType": "poi.place_of_worship",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.school",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.sports_complex",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": "-100"
                        },
                        {
                            "lightness": "45"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ff9500"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "hue": "#009aff"
                        },
                        {
                            "saturation": "100"
                        },
                        {
                            "lightness": "5"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ff9500"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "lightness": "1"
                        },
                        {
                            "saturation": "100"
                        },
                        {
                            "hue": "#009aff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#8a8a8a"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.airport",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.airport",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "lightness": "33"
                        },
                        {
                            "saturation": "-100"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.bus",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.rail",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#46bcec"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#1a94cd"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ];
            var mapOptions = {
                zoom: 10
            };
            var markerImage = new google.maps.MarkerImage(
                Perseides.Base.imgPath + 'gmap-marker-' + Analyzr.currentLang + '.png',
                new google.maps.Size(markerSize[0],markerSize[1]), //size
                null, //origin
                null, //anchor
                new google.maps.Size(markerSize[0],markerSize[1]) //scale
            );
            var markerOptions = {
                title: 'Camping Belley',
                icon: markerImage
            };

            return Perseides.Functions.googleMap('gmap', position, couleurMap, mapOptions, markerOptions);
        };


        /**
         * Fonction d'initialisation d'outil météo
         */
        app.initMeteo = function (target) {
	        "use strict";

            // Variables
            var $meteo = document.getElementById(target),
                $meteoInner = $meteo.querySelector('.meteo__inner'),
                $meteoText = $meteo.querySelector('.meteo__text');

            var meteoIcons = {
                '01d': 'meteo-soleil',
                '01n': 'meteo-lune',
                '02d': 'meteo-nuage-jour',
                '02n': 'meteo-nuage-nuit',
                '03d': 'meteo-nuage-jour',
                '03n': 'meteo-nuage-nuit',
                '04d': 'meteo-couvert',
                '04n': 'meteo-couvert',
                '09d': 'meteo-pluie',
                '09n': 'meteo-pluie',
                '10d': 'meteo-pluie',
                '10n': 'meteo-pluie',
                '11d': 'meteo-orage',
                '11n': 'meteo-orage',
                '13d': 'meteo-neige',
                '13n': 'meteo-neige',
                '50d': 'meteo-brouillard',
                '50n': 'meteo-brouillard'
            };

            // AJAX
            var request = new XMLHttpRequest();
            request.open('GET', Perseides.Base.jsPath + 'pt-meteo.php', true);
            // http://api.openweathermap.org/data/2.5/weather?id=6138337&units=metric&lang=fr&appid=acc0176a54a636d2eeb06d000b48d877

            request.onload = function () {
                if (request.status >= 200 && request.status < 400) {
                    // Success!
                    var data = JSON.parse(request.responseText);

                    // Données
                    var temp = data.main.temp;
                    var icon = data.weather[0].icon;

                    // Ajout des HTML
                    $meteoInner.setAttribute('data-icon', meteoIcons[icon]);
                    $meteoText.innerHTML = temp + '°C';
                    $meteoText.insertAdjacentHTML('afterend', '<svg class="icon-' + meteoIcons[icon] + ' icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-' + meteoIcons[icon] + '"></use></svg>');
                } else {
                    // We reached our target server, but it returned an error
                    console.log('We reached our target server, but it returned an error');
                }
            };

            request.onerror = function () {
                // There was a connection error of some sort
                console.log('There was a connection error of some sort');
            };

            request.send();
        };


	    /**
	     * Initialisation du slider de la page d'accueil
	     */
	    app.initHomeSlider = function() {
		    "use strict";

		    // Variables
            var $slider = $('#homeSlider');

		    if($slider.length !== 0) {

		        var $sliderNav = $('#homeSliderNav'),
				    $backgroundContainer = $('#homeSliderBackgrounds');

			    // Paramètres custom
			    var customParams = {
				    appendArrows: $sliderNav,
				    prevArrow: '<button type="button" class="hero--accueil__slider-nav__prev slick-prev icon-container"><svg class="icon icon-fleche-gauche"><use xlink:href="#icon-fleche-gauche"/></svg></button>',
				    nextArrow: '<button type="button" class="hero--accueil__slider-nav__next slick-next icon-container"><svg class="icon icon-fleche-droite"><use xlink:href="#icon-fleche-droite"/></svg></button>',
				    fade: true
			    };

			    $slider.on('init', function(event, slick){
				    "use strict";

				    // Variables
				    var $initSlide = $(slick.$slides);

				    // Récupération de la bonne image
				    app.getHomeSliderImageFromResolution(event);

				    $initSlide.each(function (i, e) {
					    var actif = 'opacity: 0; z-index: 0';
				        if(i === 0) {
					        actif = 'opacity: 1; z-index: 1';
                        }
					    $backgroundContainer.append('<div id="homeSliderBg' + i + '" class="hero--accueil__backgrounds__bg" style="background-image: url(' + $(e).data('image') + '); ' + actif + '"></div>');
				    });
			    });

			    $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
				    "use strict";

				    // Variables
				    var $currentBackground = $('#homeSliderBg' + currentSlide),
                        $nextBackground = $('#homeSliderBg' + nextSlide);

				    // Appliquer la transition
				    $currentBackground.css({
					    'opacity': 0,
					    'z-index': 0
				    });
				    $nextBackground.css({
					    'opacity': 1,
					    'z-index': 1
				    });
			    });

			    return Perseides.Functions.initSlickSlider($slider, customParams);
		    }
        };


	    /**
	     * Récupération de la bonne image du slider de la page d'accueil selon la résolution
	     */
	    app.getHomeSliderImageFromResolution = function(event) {
		    "use strict";

		    // Variables
		    var $slider = $('#homeSlider'),
                $sliderImages = $slider.find('.hero--accueil__slider__content'),
                resolution = 'desktop';

		    if($slider.length !== 0) {

			    // Détection de la résolution
			    if(Analyzr.isMobileSmallSize || Analyzr.isMobileMediumSize || Analyzr.isMobileLargeSize) {
				    resolution = 'mobile';
			    }
			    else if(Analyzr.isTabletSmallSize || Analyzr.isTabletMediumSize) {
				    resolution = 'tablet';
			    }
			    else if(Analyzr.isTabletLargeSize) {
				    resolution = 'tablet-large';
			    }

			    // Traitement
			    $sliderImages.each(function (i, e) {

			        // On met la bonne image dans l'attribut "data-image"
				    $(e).data('image', $(e).data('image-' + resolution));

				    // Si c'est sur le resize, il faut changer l'image directement dans son container
				    if(event.type === 'resize') {

				        $('#homeSliderBg' + i).css('background-image', 'url("' + $(e).data('image-' + resolution) + '")');

                    }

			    });

            }
        }
    };

    Perseides.Theme = new PerseidesTheme();
}(jQuery, window, document, Perseides, Analyzr));